@import '../../../../scss/theme-bootstrap';

.cart-items {
  padding: 10px 0 5px;
  margin: 5px 0;
  @include breakpoint($landscape-up) {
    border-top: 1px solid $color-lighter-gray;
  }
  &__prod-info {
    width: 58%;
    margin-#{$ldirection}: 15px;
    display: inline-block;
    float: $rdirection;
  }
  &__size,
  &__price,
  &__currency-type {
    display: inline-block;
    margin-#{$rdirection}: 5px;
  }
  &__qty,
  &__preorder {
    margin-top: 10px;
  }
  &__name {
    &-header {
      margin-bottom: 10px;
    }
  }
  &__img {
    width: 100px;
    display: inline-block;
    img {
      width: 100px;
      height: 100px;
      vertical-align: top;
      text-align: $ldirection;
    }
  }
}
